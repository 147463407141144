import moment from 'moment';

const current = new Date()
const date = current.getDate()
const days = current.getDay()
const year = current.getFullYear()

export const day = 1000 * 60 * 60 * 24

export const transformDate = value => {
  let date = new Date(value),
    month = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2)
  return [date.getFullYear(), month, day].join("-")
}

export const workingDaysBetweenDates = (start, end) => {
  /* Two working days and an sunday (not working day) */
  var startDate = start
  var endDate = end

  var timeOffDates = getDatesRange(start, end)

  // Validate input
  if (endDate <= startDate) {
    return 0
  }

  // Calculate days between dates
  var millisecondsPerDay = 86400 * 1000 // Day in milliseconds
  startDate.setHours(0, 0, 0, 1)  // Start just after midnight
  endDate.setHours(23, 59, 59, 999)  // End just before midnight
  var diff = endDate - startDate  // Milliseconds between datetime objects    
  var days = Math.ceil(diff / millisecondsPerDay)

  // Subtract two weekend days for every week in between
  var weeks = Math.floor(days / 7)
  days -= weeks * 2

  // Handle special cases
  var startDay = startDate.getDay()
  var endDay = endDate.getDay()

  // Remove weekend not previously removed.   
  if (startDay - endDay > 1) {
    days -= 2
  }
  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) {
    days--
  }
  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) {
    days--
  }
  /* Here is the code */
  holidays.forEach(day => {
    if ((day >= start) && (day <= end)) {
      /* If it is not saturday (6) or sunday (0), substract it */
      if (day.getDay() % 6 !== 0) {
        days--
      }
    }
  })

  let holidaysTimeOffDays = timeOffDates.filter((value, index) => {
    return holidays.includes(new Date(year, new Date(value).getMonth(), new Date(value).getDate()).toUTCString())
  })

  if (holidaysTimeOffDays) {
    holidaysTimeOffDays.forEach(date => {
      if (new Date(date).getDay() % 6 !== 0) {
        days--
      }
    })
  }

  return days
}

export const dateDiffInDays = (startDate, endDate) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  const utc2 = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())

  return Math.floor((utc2 - utc1) / day)
}

export const getDatesRange = (startDate, endDate) => {
  const dateArray = []
  let currentDate = moment(startDate)
  const stopDate = moment(endDate)
  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
    currentDate = moment(currentDate).add(1, 'days')
  }

  return dateArray
}

export const getWeeksInMonth = (month, year) =>
  Array
    .from({ length: (new Date(year, month + 1, 0) - new Date(year, month, 0)) / 864E5 },
      (_, i) => new Date(year, month, i + 1))
    .reduce((res, date, idx, self) => {
      const itsMonday = date.getDay() === 1,
        itsSunday = date.getDay() === 0,
        monthStart = idx === 0,
        monthEnd = idx === self.length - 1
      if (itsMonday || monthStart)
        res.push({
          week: (res[res.length - 1] || { week: 0 }).week + 1,
          start: moment(date).format("YYYY-MM-DD"),
          end: (monthStart ? moment(date).format("YYYY-MM-DD") : '')
        })
      if (itsSunday || monthEnd)
        res[res.length - 1].end = moment(date).format("YYYY-MM-DD")
      return res
    }, [])


const getEaster = year => {
  var a = year % 19 //a = position of the year in the 19 year cycle (0-18)
  var T = (8 + 11 * a) % 30 // Julian Epact
  var month = 5 //Sets the month to March
  var K = Math.floor((year / 100) - (year / 400) - 2) //Calculates K

  var iPanArxM = 0
  var iPas = 0
  var iPanArx = 21 + (53 - T) % 30 //Calculates the Julian Full-Moon

  if (iPanArx > 31) { //if the number is bigger the total of days in March, it is converted to respective day in April
    iPanArxM = iPanArx - 31
    month = 4 //and the month is set to April
  } else {
    iPanArxM = iPanArx
  } //else it remains the same but it is assigned to another variable

  var iPanTel = iPanArxM + K //calculates the iPanTel, not sure what it is...
  var Y = (year + Math.floor(year / 4) + iPanArx) % 7 //calculates the position Y of iPanTel in the week days (0-Sunday,1-Monday κτλ)
  iPas = iPanTel + (7 - Y) //calculates the Orthodox Easter date

  if (iPas > 30 && month === 4) { //if number of April days > 30, they are converted to May days.
    month = 5 // and month is set to May
    iPas = iPas - 30
  } else if (iPas > 31 && month === 3) { //else if number of March days > 31, they are converted to April days.
    month = 4 // and month is to April
    iPas = iPas - 31
  }
  if (year > 2099) {
    iPas++
  } else if (year < 2000) {
    iPas++
  } //corrects an error for dates bigger than 2099
  var arr = [] //creates array and pushes the values inside of it, so they can be transfered to the HTML form
  arr.push(iPas)
  arr.push(month - 1)

  return arr
}

const getEasterRelatedDates = (year) => {
  const easterDate = new Date(year, getEaster(year)[1], getEaster(year)[0])

  let bigFriday = new Date(easterDate)
  let secondEasterDay = new Date(easterDate)
  let pentecostDay = new Date(easterDate)

  bigFriday.setDate(bigFriday.getDate() - 2)
  secondEasterDay.setDate(secondEasterDay.getDate() + 1)
  pentecostDay.setDate(pentecostDay.getDate() + 50)

  return [
    createDateToUtc(bigFriday.getFullYear(), bigFriday.getMonth(), bigFriday.getDay()),
    createDateToUtc(secondEasterDay.getFullYear(), secondEasterDay.getMonth(), secondEasterDay.getDay()),
    createDateToUtc(pentecostDay.getFullYear(), pentecostDay.getMonth(), pentecostDay.getDay())]
}

const createDateToUtc = (year, month, day) => {
  return new Date(year, month, day).toUTCString()
}

export const holidays = [createDateToUtc(year, 0, 1), createDateToUtc(year, 0, 2), createDateToUtc(year, 0, 24), createDateToUtc(year, 4, 1), createDateToUtc(year, 5, 1), createDateToUtc(year, 7, 15), createDateToUtc(year, 10, 30), createDateToUtc(year, 11, 1), createDateToUtc(year, 11, 25), createDateToUtc(year, 11, 26)].concat(getEasterRelatedDates(year))

export const weekOfMonth = Math.ceil((date + 6 - days) / 7)
export const firstDay = moment(new Date(current.getFullYear(), current.getMonth(), 1)).format("YYYY-MM-DD")
export const lastDay = moment(new Date(current.getFullYear(), current.getMonth() + 1, 0)).format("YYYY-MM-DD")

export const firstDayOfWeek = moment().startOf('isoweek').format('YYYY-MM-DD')
export const lastDayOfWeek = moment().endOf('isoweek').format('YYYY-MM-DD')

export const checkDateEquality = (firstDate, secondDate) => {
  return (
    new Date(firstDate).getMonth() === new Date(secondDate).getMonth() && new Date(firstDate).getDate() === new Date(secondDate).getDate()
  )
}