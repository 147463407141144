import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, FormControl, RadioGroup, Radio, FormControlLabel, Typography } from '@material-ui/core';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

//internal 
import { getRegisterTimeOff, getHolidayRegister, getUsers } from '../globals/Routes';
import { getWeeksInMonth, weekOfMonth, firstDay, lastDay, getDatesRange } from '../helpers/DateHelpers';
import SnackBar from '../components/Snackbar';
import Colors from '../globals/Colors';
import DateRangeModal from '../components/DateRangeModal';
import { minWidth } from '../globals/Constants';
import useWindowDimensions from '../hooks/LayoutHooks';

const useStyles = makeStyles({
    root: {
        marginTop: -45,
        marginLeft: 20,
        marginRight: 20,
        minWidth: minWidth,
        display: "flex",
        flexDirection: "row"
    },
    table: {
        backgroundColor: Colors.thirdColor
    },
    headerTypography: {
        paddingRight: 0,
        marginLeft: 0,
        marginRight: 0
    },
    headerTitle: {
        fontWeight: 900,
        fontSize: 16,
        color: Colors.whiteColor,
    },
    footerTypography: {
        fontWeight: 600,
        fontSize: 18,
        color: Colors.whiteColor
    },
    radio: {
        '&$checked': {
            color: Colors.firstColor
        }
    },
    checked: {},
    dateHeader: {
        color: Colors.whiteColor,
        backgroundColor: Colors.firstColor,
        textAlign: "center",
        borderTopRightRadius: 5,
        borderTopLeftRadius: 5,
        fontSize: 16,
        fontWeight: 900,
        padding: 11
    },
    dateContent: {
        backgroundColor: Colors.thirdColor,
        borderTop: "1px solid #D3D3D3",
        paddingLeft: 15,
        height: 37
    },
    download: {
        backgroundColor: Colors.firstColor,
        color: Colors.whiteColor,
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        border: "none",
        padding: 13,
        fontSize: 16,
        fontWeight: 900,
        width: "100%",
        cursor: "pointer",
        height: 45
    },
    noScrollBar: {
        "&::-webkit-scrollbar": {
            display: 'none'
        }
    }
})

const dates = getWeeksInMonth(new Date().getMonth(), new Date().getFullYear())

export default function Register() {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const { height } = useWindowDimensions()

    const [loading, setLoading] = useState(false)

    const [users, setUsers] = useState([])
    const [timeoffList, setTimeoffList] = useState([])
    const [holidayList, setHolidayList] = useState([])

    const [registerList, setRegisterList] = useState([])

    const [checked, setChecked] = useState({ startDate: dates.filter(e => e.week === weekOfMonth).map(date => date.start)[0], endDate: dates.filter(e => e.week === weekOfMonth).map(date => date.end)[0] })
    const [customDate, setCustomDate] = useState({ startDate: moment().format("MM-DD-YYYY"), endDate: moment().format("MM-DD-YYYY") })

    const [range, setRange] = useState([])

    const snackbarRef = useRef()
    const dateRangeModalRef = useRef()

    const handleCheckedChange = event => {
        if (event && event.target) {
            const time = event.target.value.split(" ")
            setChecked({ startDate: time[0], endDate: time[1] })
            setCustomDate({ startDate: moment().format("MM-DD-YYYY"), endDate: moment().format("MM-DD-YYYY") })
        } else {
            setChecked({ startDate: moment(event[0].startDate).format("YYYY-MM-DD"), endDate: moment(event[0].endDate).format("YYYY-MM-DD") })
            setCustomDate({ startDate: moment(event[0].startDate).format("YYYY-MM-DD"), endDate: moment(event[0].endDate).format("YYYY-MM-DD") })
        }
    }

    const openSnackbar = (message, severity) => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const getTimeoffRequest = () => {
        setLoading(true)
        axios.get(getRegisterTimeOff, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            params: { startDate: new Date(2020, 0, 1).toISOString().slice(0, 10), endDate: new Date(2100, 11, 31).toISOString().slice(0, 10) }
        }).then(response => {
            if (response.data.length) {
                setTimeoffList(response.data)
                setLoading(false)
            } else {
                setTimeoffList([])
                setLoading(false)
            }
        }).catch(error => {
            openSnackbar(i18n.t("timeoff.problemFetching"), "error")
        })

        axios.get(getHolidayRegister, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
        }).then(response => {
            if (response.data.length) {
                setHolidayList(response.data)
                setLoading(false)
            } else {
                setHolidayList([])
                setLoading(false)
            }
        }).catch(error => {
            openSnackbar(i18n.t("timeoff.problemFetching"), "error")
        })

        axios.get(getUsers, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackbar(i18n.t("filterModal.errorUsers"), "error")
            } else {
                setUsers(response.data)
            }
        })
    }

    useEffect(() => {
        getTimeoffRequest()
        setRange(getDatesRange(checked.startDate, checked.endDate))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked])

    useEffect(() => {
        setRegisterList([...timeoffList, ...holidayList])
    }, [timeoffList, holidayList])

    return (
        <div className={classes.root}>
            <div style={{ marginRight: 20 }}>
                <FormControl component="fieldset">
                    <div className={classes.dateHeader}>{i18n.t("register.dateRange")}</div>
                    <RadioGroup aria-label="gender" name="gender1" value={checked.startDate + " " + checked.endDate} onChange={handleCheckedChange}>
                        {dates.map(week =>
                            <div key={uuid()} className={classes.dateContent}>
                                <FormControlLabel
                                    value={week.start + " " + week.end}
                                    control={
                                        <Radio disableRipple
                                            size="small"
                                            classes={{ root: classes.radio, checked: classes.checked }}
                                            style={{ backgroundColor: 'transparent' }} />
                                    }
                                    label={
                                        <Typography style={{ fontSize: 16, marginTop: -2 }}>
                                            {moment(week.start).format("MMM DD") + " - " + moment(week.end).format("DD, YYYY")}
                                        </Typography>
                                    }
                                />
                            </div>)}
                        <div className={classes.dateContent}>
                            <FormControlLabel
                                value={firstDay + " " + lastDay}
                                control={
                                    <Radio
                                        disableRipple
                                        size="small"
                                        classes={{ root: classes.radio, checked: classes.checked }}
                                        style={{ backgroundColor: 'transparent' }} />
                                }

                                label={moment(new Date()).format("MMMM")} />
                        </div>
                    </RadioGroup>
                    <div className={classes.dateContent}>
                        <FormControlLabel
                            value={customDate.startDate + " " + customDate.endDate}
                            onClick={() => {
                                dateRangeModalRef.current.setOpen(true);
                                dateRangeModalRef.current.setDate([
                                    {
                                        startDate: new Date(customDate.startDate),
                                        endDate: new Date(customDate.endDate),
                                        key: 'selection'
                                    }
                                ])
                            }}
                            control={
                                <Radio disableRipple
                                    size="small"
                                    checked={customDate.startDate !== moment().format("MM-DD-YYYY")}
                                    classes={{ root: classes.radio, checked: classes.checked }}
                                    style={{ backgroundColor: 'transparent' }} />
                            }
                            label={checked.startDate === customDate.startDate && checked.endDate === customDate.endDate ?
                                new Date(customDate.startDate).getDay() === new Date(customDate.endDate).getDay() ?
                                    moment(customDate.startDate).format("MMM DD, YYYY") :
                                    new Date(customDate.startDate).getMonth() === new Date(customDate.endDate).getMonth() ?
                                        moment(customDate.startDate).format("MMM DD") + " - " + moment(customDate.endDate).format("DD, YYYY") :
                                        new Date(customDate.startDate).getFullYear() === new Date(customDate.endDate).getFullYear() ? moment(customDate.startDate).format("MMM DD") + " - " + moment(customDate.endDate).format("MMM DD, YYYY") :
                                            moment(customDate.startDate).format("MMM DD, YYYY") + " - " + moment(customDate.endDate).format("MMM DD, YYYY") : i18n.t("reports.custom")} />
                    </div>

                    <div style={{ width: "100%", backgroundColor: Colors.firstColor, borderBottomRightRadius: 5, borderBottomLeftRadius: 5 }}>
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            table="registerTable"
                            className={classes.download}
                            filename={`Register ${range[0] === range[range.length - 1] ? `${range[0]}` : `between ${range[0]} and ${range[range.length - 1]}`}`}
                            sheet="register"
                            buttonText={i18n.t("register.export")} />
                    </div>
                </FormControl>

                <DateRangeModal ref={dateRangeModalRef} onChangeDate={handleCheckedChange} />
            </div>

            <div style={{ flex: 1 }}>
                <TableContainer style={{ maxHeight: height - 105, borderRadius: 5 }}>
                    <Table id="registerTable" aria-label="simple table" className={classes.table} style={{ tableLayout: "fixed" }} size="small">
                        <TableHead style={{ height: 45 }}>
                            <TableRow style={{ backgroundColor: Colors.firstColor }}>
                                <TableCell className={classes.headerTypography} style={{ borderTopLeftRadius: 5 }}>
                                    <Typography noWrap={true} className={classes.headerTitle}>{i18n.t("register.date")}</Typography>
                                </TableCell>

                                {users.map(user => user.isEmployee && ((range.includes(moment(user.endDate).format('YYYY-MM-DD')) || range.includes(moment(user.startDate).format('YYYY-MM-DD'))) || (!(user.endDate && new Date(user.endDate).getTime() <= new Date(range.at(-1)).getTime()) && new Date(range[0]).getTime() >= new Date(user.startDate).getTime())) ? <TableCell key={user.id} className={classes.headerTypography} style={{ borderTopRightRadius: user === users[users.length - 1] ? 5 : 0 }}>
                                    <Typography noWrap={true} className={classes.headerTitle}>{user.name}</Typography>
                                </TableCell> : null)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                !loading && range.map(day => (
                                    <TableRow key={day} style={{ height: 35, padding: 5 }}>
                                        <TableCell>
                                            <Typography noWrap={true} style={{ fontSize: 16, fontWeight: 600 }}>{day}</Typography>
                                        </TableCell>

                                        {/*eslint-disable-next-line*/}
                                        {users.filter(user => user.isEmployee && ((range.includes(moment(user.endDate).format('YYYY-MM-DD')) || range.includes(moment(user.startDate).format('YYYY-MM-DD'))) || (!(user.endDate && new Date(user.endDate).getTime() <= new Date(range.at(-1)).getTime()) && new Date(range[0]).getTime() >= new Date(user.startDate).getTime()))).map(user => {
                                            const timeoffUser = registerList.filter(timeoff => timeoff.userName === user.name && getDatesRange(timeoff.startDate, timeoff.endDate).includes(day) && (new Date(day).getDay() % 6))
                                            const timeoffFormatted = timeoffUser.map(obj => obj.userName === user.name && obj.projectName.match(/\b(\w)/g).join("").toUpperCase())
                                            if (range.includes(moment(user.startDate).format('YYYY-MM-DD')) || new Date(range[0]).getTime() >= new Date(user.startDate).getTime()) {
                                                if (new Date(day).getTime() <= new Date(user.startDate).getTime()) {
                                                    return (
                                                        <TableCell key={uuid()} style={{ fontSize: 16 }}>-</TableCell>
                                                    )
                                                } else if (user.endDate && new Date(day).getTime() >= new Date(user.endDate).getTime()) {
                                                    return (
                                                        <TableCell key={uuid()} style={{ fontSize: 16 }}>-</TableCell>
                                                    )
                                                } else if (timeoffUser && timeoffUser.length) {
                                                    return (
                                                        <TableCell key={uuid()} style={{ fontSize: 16 }}>
                                                            {timeoffFormatted.includes('PH') ? 'PH' : timeoffFormatted}
                                                        </TableCell>
                                                    )

                                                } else if (new Date(day).getDay() === 6 || new Date(day).getDay() === 0) {
                                                    return (
                                                        <TableCell key={uuid()} style={{ fontSize: 16 }}>-</TableCell>
                                                    )
                                                } else if (new Date(day).getTime() > new Date().getTime()) {
                                                    return (
                                                        <TableCell key={uuid()} style={{ fontSize: 16 }}>-</TableCell>
                                                    )
                                                } else if (new Date(range[0]).getTime() > new Date(user.startDate).getTime()) {
                                                    return (
                                                        <TableCell key={uuid()} style={{ fontSize: 16 }}>{user.hours}h, {(Math.random() * (36.9 - 35.5) + 35.0).toFixed(1)}{i18n.t("register.temperature")}</TableCell>
                                                    )
                                                } else {
                                                    return (
                                                        <TableCell key={uuid()} style={{ fontSize: 16 }}>{user.hours}h, {(Math.random() * (36.9 - 35.5) + 35.0).toFixed(1)}{i18n.t("register.temperature")}</TableCell>
                                                    )
                                                }

                                            }
                                        })}
                                    </TableRow>
                                ))
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <SnackBar ref={snackbarRef} />
        </div>
    )
}