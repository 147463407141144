import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography, IconButton } from '@material-ui/core';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import * as XLSX from "xlsx";
import { useTranslation } from 'react-i18next';
import FilterListIcon from '@material-ui/icons/FilterList';
import GetAppIcon from '@material-ui/icons/GetApp';

//internal 
import { getReports, getReportsUsersHours, getReportsTotals, getUsers, excelWorklog } from '../globals/Routes';
import SnackBar from '../components/Snackbar';
import Colors from '../globals/Colors';
import { minWidth } from '../globals/Constants';
import SearchBar from '../components/SearchBar';
import { firstDay, getDatesRange, lastDay } from '../helpers/DateHelpers'
import ReportsFilterModal from '../components/ReportsFilterModal';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    marginTop: -45,
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 75,
    minWidth: minWidth,
  },
  table: {
    backgroundColor: Colors.thirdColor
  },
  headerTypography: {
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0
  },
  headerTitle: {
    fontWeight: 900,
    fontSize: 16,
    color: Colors.whiteColor,
  },
  footerTypography: {
    fontWeight: 600,
    fontSize: 16,
    color: Colors.whiteColor
  },
  search: {
    marginRight: 35,
    width: "100%",
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: Colors.firstColor
    },
  },
  actionDiv: {
    display: "flex",
    alignItems: "center",
    marginRight: 6,
  },
  iconButton: {
    backgroundColor: Colors.firstColor,
    color: Colors.whiteColor,
    width: 30,
    height: 30,
    '&:hover': {
      backgroundColor: Colors.secondColor
    }
  },
  buttonIcon: {
    height: 18,
    width: 18,
    color: Colors.whiteColor
  },
})

export default function Reports() {
  const classes = useStyles()
  const { i18n } = useTranslation()

  const [reportsList, setReportsList] = useState([])
  const [reportsSearchList, setReportsSearchList] = useState([])
  const [search, setSearch] = useState('')

  const [result, setResult] = useState([])

  const [users, setUsers] = useState([])
  const [usersHours, setUsersHours] = useState([])
  const [totals, setTotals] = useState([])

  const [worklogList, setWorklogList] = useState([])

  const [reportsFilters, setReportsFilters] = useState(JSON.parse(sessionStorage.getItem("reportsFilters")) || null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  let dataArray = []

  const snackbarRef = useRef()
  const searchBarRef = useRef()
  const reportsFilterModalRef = useRef()

  const openSnackBar = (message, severity = "success") => {
    snackbarRef.current.setOpen(true)
    snackbarRef.current.setMessage(message)
    snackbarRef.current.setSeverity(severity)
  }

  const statutes = [{ id: 2, title: 'All' }, { id: 1, title: 'Confirmed' }, { id: 0, title: 'Pending' }]

  const clearFilters = () => {
    setSearch('')
    searchBarRef.current.setText('')
    reportsFilters && setReportsFilters()
    sessionStorage.removeItem("reportsFilteredStatus")
    sessionStorage.removeItem('consistenceReportsFilteredStatus')
    sessionStorage.removeItem("reportsFilteredProjects")
    sessionStorage.removeItem('consistenceReportsFilteredProjects')
    sessionStorage.removeItem("reportsFilteredClients")
    sessionStorage.removeItem('consistenceReportsFilteredClients')
    sessionStorage.removeItem("reportsFilteredDate")
    sessionStorage.removeItem("reportsFilters")

    const dialog = reportsFilterModalRef.current
    dialog.setStatusValue(statutes[0].id)
    dialog.setProjectValue([])
    dialog.setClientValue([])
    dialog.setDate(null)
    dialog.setStartDate(firstDay)
    dialog.setEndDate(lastDay)
    dialog.setConsistenceProject([])
    dialog.setConsistenceClient([])
    dialog.setConsistenceStatus(statutes[0])
  }

  let startDate = firstDay
  let endDate = lastDay
  let statusValue = null
  let projectIds = []
  let clientIds = []

  if (reportsFilters && reportsFilters.startDate) {
    startDate = reportsFilters.startDate
  }

  if (reportsFilters && reportsFilters.endDate) {
    endDate = reportsFilters.endDate
  }

  if (reportsFilters && Number.isFinite(reportsFilters.statusValue)) {
    statusValue = reportsFilters.statusValue
  }

  if (reportsFilters && reportsFilters.projectValue) {
    projectIds = reportsFilters.projectValue
  }

  if (reportsFilters && reportsFilters.clientValue) {
    clientIds = reportsFilters.clientValue
  }

  const getWorklog = () => {
    axios.get(excelWorklog, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      params: { startDate: startDate, endDate: endDate }
    }).then(response => {
      setWorklogList(response.data)
    }).catch(error => {
      openSnackBar(i18n.t("worklogs.problemFetching"), "error")
    })
  }

  const getDatabaseUsers = () => {
    axios.get(getUsers, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(response => {
      if (!response.data.length) {
        openSnackBar(i18n.t("timeoffFilterModal.errorUsers"), "error")
      } else {
        setUsers(response.data)
      }
    })
  }

  const getReportsRequest = () => {
    axios.get(getReports, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      params: { startDate: startDate, endDate: endDate, status: statusValue !== 2 ? statusValue : null, projectIds: projectIds, clientIds: clientIds }
    }).then(response => {
      if (!response.data.length) {
        setReportsList([])
        setReportsSearchList([])
        dataArray = []
      } else {
        setReportsList(response.data)
        setReportsSearchList(response.data)
      }
    }).catch(error => {
      openSnackBar(i18n.t("reports.reportsError"), "error")
    })
  }

  const getReportsUsersRequest = () => {
    axios.get(getReportsUsersHours, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      params: { startDate: startDate, endDate: endDate, status: statusValue !== 2 ? statusValue : null, projectIds: projectIds, clientIds: clientIds }
    }).then(response => {
      if (!response.data.length) {
        setUsersHours([])
      } else {
        setUsersHours(response.data)
      }
    }).catch(error => {
      openSnackBar(i18n.t("reports.reportsError"), "error")
    })
  }

  const getReportsTotalsRequest = () => {
    axios.get(getReportsTotals, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      params: { startDate: startDate, endDate: endDate, status: statusValue !== 2 ? statusValue : null, projectIds: projectIds, clientIds: clientIds }
    }).then(response => {
      if (!response.data.length) {
        setTotals([])
      } else {
        setTotals(response.data)
      }
    }).catch(error => {
      openSnackBar(i18n.t("reports.reportsError"), "error")
    })
  }

  const stringToArrayBuffer = string => {
    const buf = new ArrayBuffer(string.length)
    const view = new Uint8Array(buf)
    for (var i = 0; i !== string.length; ++i) view[i] = string.charCodeAt(i) & 0xFF

    return buf
  }

  const createExcel = () => {
    let targetTableElm1 = document.getElementById("reportsTable")

    const workbook = { SheetNames: [], Sheets: {} }
    const reportsWorksheet = XLSX.utils.table_to_book(targetTableElm1).Sheets.Sheet1
    workbook.SheetNames.push("Reports")
    workbook.Sheets["Reports"] = reportsWorksheet

    let usersWorksheet = []
    worklogList.length && users.map(user => {
      usersWorksheet = XLSX.utils.json_to_sheet(grouped.get(user.name))
      workbook.SheetNames.push(`${user.name}`)
      workbook.Sheets[`${user.name}`] = usersWorksheet

      return 1
    })

    const blob = new Blob([stringToArrayBuffer(XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' }))], {
      type: "application/octet-stream"
    })
    const link = document.createElement('a')
    link.href = window.URL.createObjectURL(blob)
    link.download = `Report ${startDate === endDate ? `${startDate}` : `between ${startDate} and ${endDate}`}.xlsx`

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  function groupBy(list, keyGetter) {
    const map = new Map()
    list.forEach((item) => {
      const key = keyGetter(item)
      const collection = map.get(key)
      if (!collection) {
        map.set(key, [item])
      } else {
        collection.push(item)
      }
    })
    return map
  }

  const grouped = groupBy(worklogList, work => work.UserName)

  const totalSum = array => {
    var total = 0
    for (var i = 0; i < array.length; i++) {
      total += array[i].workedHours
    }
    return total
  }

  const handleFiltersChange = filters => {
    setReportsFilters(filters)
    sessionStorage.setItem("reportsFilters", JSON.stringify(filters))
    setSearch('')
    searchBarRef.current.setText('')
  }

  useEffect(() => {
    setReportsSearchList(reportsList)
  }, [reportsList])

  useEffect(() => {
    setReportsSearchList(reportsList.filter(report =>
      report.clientName.toLowerCase().includes(search.toLowerCase())
      || report.projectName.toLowerCase().includes(search.toLowerCase())
      || report.userName.toLowerCase().includes(search.toLowerCase())
    ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  useEffect(() => {
    getDatabaseUsers()
    getWorklog()
    getReportsRequest()
    getReportsUsersRequest()
    getReportsTotalsRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    getWorklog()
    getReportsRequest()
    getReportsUsersRequest()
    getReportsTotalsRequest()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportsFilters])

  useEffect(() => {
    setResult(reportsSearchList.reduce((accumulator, item) => {
      var key = item.clientName + " - " + item.projectName
      accumulator[key] = accumulator[key] || []
      accumulator[key].push(item)
      return accumulator;
    }, Object.create(null)))

  }, [reportsList, reportsSearchList])

  Object.keys(result).map((key) => (
    dataArray.push({ name: key, data: result[key] })
  ))

  return (
    <div className={classes.root}>
      <div className={classes.actionDiv}>
        <SearchBar
          ref={searchBarRef}
          label='Search by client name or project name'
          className={classes.search}
          onChange={(value) => setSearch(value)}
        />

        <IconButton
          className={classes.iconButton}
          onClick={() => reportsFilterModalRef.current.setOpen(true)}>
          <FilterListIcon className={classes.buttonIcon} />
        </IconButton>

        <ReportsFilterModal
          ref={reportsFilterModalRef}
          setFilters={handleFiltersChange}
          clearFilters={clearFilters}
        />

        <IconButton
          className={classes.iconButton}
          style={{ marginLeft: 30 }}
          onClick={() => createExcel()}>
          <GetAppIcon className={classes.buttonIcon} />
        </IconButton>
      </div>


      <div style={{ flex: 1 }}>
        <TableContainer style={{ tableLayout: "auto", borderRadius: 5, marginTop: 20 }}>
          <Table id="reportsTable" aria-label="simple table" className={classes.table} style={{ tableLayout: "auto" }} size="small">
            <TableHead style={{ height: 45 }}>
              <TableRow style={{ backgroundColor: Colors.firstColor }}>
                <TableCell className={classes.headerTypography} >
                  <Typography noWrap={true} className={classes.headerTitle}>{i18n.t("reports.projects")}</Typography>
                </TableCell>
                {users && users.filter(user => (getDatesRange(startDate, endDate).includes(moment(user.endDate).format('YYYY-MM-DD')) || getDatesRange(startDate, endDate).includes(moment(user.startDate).format('YYYY-MM-DD'))) || (!(user.endDate && new Date(user.endDate).getTime() <= new Date(getDatesRange(startDate, endDate).at(-1)).getTime()) && new Date(getDatesRange(startDate, endDate)[0]).getTime() >= new Date(user.startDate).getTime())).map(user => <TableCell key={user.id} className={classes.headerTypography}>
                  <Typography noWrap={true} className={classes.headerTitle}>{user.name} {i18n.t("reports.hours")}</Typography>
                </TableCell>)}
                <TableCell className={classes.headerTypography} align="right" style={{ width: "50%" }}>
                  <Typography noWrap={true} className={classes.headerTitle} style={{ marginRight: 50 }}>{i18n.t("reports.total")} {i18n.t("reports.hours")}</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataArray.map(report => (
                <TableRow key={report.name}>
                  <TableCell>
                    <Typography noWrap={true} style={{ fontSize: 16, fontWeight: 600 }}>{report.name}</Typography>
                  </TableCell>

                  {users && users.filter(user => (getDatesRange(startDate, endDate).includes(moment(user.endDate).format('YYYY-MM-DD')) || getDatesRange(startDate, endDate).includes(moment(user.startDate).format('YYYY-MM-DD'))) || (!(user.endDate && new Date(user.endDate).getTime() <= new Date(getDatesRange(startDate, endDate).at(-1)).getTime()) && new Date(getDatesRange(startDate, endDate)[0]).getTime() >= new Date(user.startDate).getTime())).map(user => {
                    const userOnProject = report.data.filter(obj => obj.userName === user.name)
                    if (userOnProject.length) {
                      return (
                        <TableCell key={uuid()} style={{ fontSize: 16 }}>
                          {userOnProject.map(obj => obj.userName === user.name && obj.workedHours)}
                        </TableCell>
                      )
                    }

                    return (
                      <TableCell key={uuid()} style={{ fontSize: 16 }}>0</TableCell>
                    )
                  })}
                  <TableCell align="right">
                    <Typography  style={{ fontSize: 16, marginRight: 50 }}>
                      {totalSum(report.data)}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}

              <TableRow style={{ backgroundColor: Colors.firstColor, height: 45 }}>
                <TableCell className={classes.footerTypography}>{i18n.t("reports.total")}</TableCell>
                {users && users.filter(user => (getDatesRange(startDate, endDate).includes(moment(user.endDate).format('YYYY-MM-DD')) || getDatesRange(startDate, endDate).includes(moment(user.startDate).format('YYYY-MM-DD'))) || (!(user.endDate && new Date(user.endDate).getTime() <= new Date(getDatesRange(startDate, endDate).at(-1)).getTime()) && new Date(getDatesRange(startDate, endDate)[0]).getTime() >= new Date(user.startDate).getTime())).map(user => {
                  const userOnProject = usersHours.filter(obj => obj.name === user.name)
                  if (userOnProject.length) {
                    return (
                      <TableCell key={uuid()} className={classes.footerTypography}>
                        {userOnProject.map(obj => obj.name === user.name && obj.totalHours)} h
                      </TableCell>
                    )
                  }

                  return (
                    <TableCell key={uuid()} className={classes.footerTypography}>0 h</TableCell>
                  )
                })}
                {
                  !totals.length && <React.Fragment key={uuid()}>
                    <TableCell className={classes.footerTypography} align="right">
                      <Typography className={classes.footerTypography} style={{ marginRight: 50 }}>0 h</Typography>
                    </TableCell>
                  </React.Fragment>
                }

                {totals.map(total =>
                  <React.Fragment key={uuid()}>
                    <TableCell className={classes.footerTypography} align="right">
                      <Typography className={classes.footerTypography} style={{ marginRight: 50 }} noWrap={true}>{total.totalHours ? total.totalHours : 0} h</Typography>
                    </TableCell>
                  </React.Fragment>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <SnackBar ref={snackbarRef} />
    </div>
  )
}