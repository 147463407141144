import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Button, CssBaseline, Typography } from '@material-ui/core';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { useHistory, Route, Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//internal
import Colors from '../globals/Colors';
import { logoutUser } from '../utils/User';
import { minWidth } from '../globals/Constants';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "fixed",
        bottom: theme.spacing(3),
        right: 27,
    },
    toolBar: {
        marginLeft: -12,
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    button: {
        marginLeft: 15,
        fontSize: 20,
        color: Colors.whiteColor,
        textTransform: "none"
    },
    logoutButton: {
        marginLeft: 15,
        fontSize: 20,
        color: Colors.whiteColor,
        textTransform: "none",
        '&:hover': {
            color: Colors.redColor
        }
    },
    logo: {
        height: 48,
        width: 48,
    },
    title: {
        fontSize: 22
    },
    link: {
        textDecoration: "none",
        color: Colors.whiteColor
    }
}))

export default function AppBarRenderer(props) {
    const classes = useStyles()
    const history = useHistory()
    const location = useLocation()

    const { i18n } = useTranslation()

    const [user, setUser] = useState()
    const [currentTab, setCurrentTab] = useState()

    const onClick = (route, title) => {
        history.push(route)
        setCurrentTab(title)
    }

    const getRouteButton = (classes, title, route) => (
        <Route render={() => (
            <Button
                className={classes.button}
                style={{ backgroundColor: title === currentTab ? Colors.secondColor : Colors.firstColor }}
                onClick={() => onClick(route, title)}>
                {title}
            </Button>
        )}
        />
    )

    const getLogOutButton = (classes, title) => (
        <Route render={({ history }) => (
            <Button
                className={classes.logoutButton}
                onClick={() => { logoutUser(); history.push('/'); sessionStorage.clear(); return false }}>
                {title}
            </Button>
        )} />
    )

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem("user")))
        setCurrentTab(location.pathname.substring(1) === "Timeoff" ? "Time off" : location.pathname.substring(1))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div style={{ minWidth: minWidth }}>
            <CssBaseline />
            <AppBar position="static" style={{ backgroundColor: Colors.firstColor }}>
                <Toolbar className={classes.toolBar}>
                    <PhoneIphoneIcon className={classes.logo} />
                    <Link to="/Worklogs" className={classes.link} onClick={() => setCurrentTab("Worklogs")}>
                        <Typography className={classes.title}>{i18n.t("appBar.SM")} - {user && user.name}</Typography>
                    </Link>

                    <div style={{ flex: 1 }} />

                    {getRouteButton(classes, i18n.t("appBar.worklogs"), '/Worklogs')}
                    {user?.isEmployee === 1 && getRouteButton(classes, i18n.t("appBar.timeOff"), '/Timeoff')}
                    {user?.role === 1 && getRouteButton(classes, i18n.t("appBar.register"), '/Register')}
                    {user?.role === 1 && getRouteButton(classes, i18n.t("appBar.reports"), '/Reports')}
                    {user?.role === 1 && getRouteButton(classes, i18n.t("appBar.dashboard"), '/Dashboard')}
                    {(user?.role === 1 || user?.isEmployee === 0) && getRouteButton(classes, i18n.t("appBar.invoice"), '/Invoice')}
                    {user?.role === 1 && getRouteButton(classes, i18n.t("appBar.logs"), '/Logs')}
                    {getLogOutButton(classes, i18n.t("appBar.logout"))}
                </Toolbar>
            </AppBar>

            <Toolbar id="back-to-top-anchor" />
        </div>
    )
}