import React, { useState, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, TextField, OutlinedInput, InputAdornment, FormControl, InputLabel, Button, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import DateRangeIcon from '@material-ui/icons/DateRange';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePicker from 'react-multi-date-picker';
import { saveAs } from 'file-saver';
import { pdf } from "@react-pdf/renderer"
import { PDFDocument } from 'pdf-lib';

//internal
import SnackBar from '../components/Snackbar';
import Colors from '../globals/Colors';
import { invoice, getClients } from '../globals/Routes';
import { minWidth } from '../globals/Constants';
import useWindowDimensions from '../hooks/LayoutHooks'
import DateRangeModal from '../components/DateRangeModal';
import InvoicePDF from '../components/InvoicePDF';
import CristianSerea from '../assets/icons/CristianSerea.png'
import MihailStoica from '../assets/icons/MihailStoica.png'

const useStyles = makeStyles({
    root: {
        marginTop: -45,
        marginLeft: 20,
        marginRight: 20,
        minWidth: minWidth,
        display: "flex",
    },
    download: {
        width: '100%',
        color: Colors.whiteColor,
        backgroundColor: Colors.firstColor,
        textAlign: "center",
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        fontSize: 16,
        fontWeight: 900,
        height: 45,
        textTransform: "none",
        '&:hover': {
            backgroundColor: Colors.secondColor
        }
    },
})

const admins = [{ id: 0, title: 'None' }, { id: 1, title: 'Cristian Serea' }, { id: 2, title: 'Mihail Stoica' }]

export default function Invoice() {
    const classes = useStyles()
    const { i18n } = useTranslation()

    const { height, width } = useWindowDimensions()

    const [invoices, setInvoices] = useState()

    const [taskHours, setTaskHours] = useState({})
    const [totalHours, setTotalHours] = useState()
    const [formattedHours, setFormattedHours] = useState()

    const [selectedUser, setSelectedUser] = useState(admins[0])

    const [number, setNumber] = useState()

    const [date, setDate] = useState(new Date())

    const [dateRange, setDateRange] = useState()
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState()

    const [disabled, setDisabled] = useState(true)

    const dateRangeModalRef = useRef()
    const snackbarRef = useRef()
    const fileRef = useRef()

    const openSnackBar = (message, severity = "success") => {
        snackbarRef.current.setOpen(true)
        snackbarRef.current.setMessage(message)
        snackbarRef.current.setSeverity(severity)
    }

    const setDates = data => {
        setStartDate(transformDate(data.startDate))
        setEndDate(transformDate(data.endDate))
    }

    const getTaskHoursRequest = () => {
        axios.get(invoice, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            },
            params: { startDate: new Date(startDate).toISOString().slice(0, 10), endDate: new Date(endDate).toISOString().slice(0, 10), client: JSON.parse(localStorage.getItem('user')).isEmployee === 1 ? selectedClient.id : null, userId: JSON.parse(localStorage.getItem('user')).isEmployee === 0 ? JSON.parse(localStorage.getItem('user')).id : null }
        }).then(response => {
            if (response.data.length) {
                let hours = response.data.reduce((accumulator, item) => {
                    accumulator[item.name] = accumulator[item.name] || []
                    accumulator[item.name].push(item)
                    return accumulator;
                }, Object.create(null))

                setTotalHours(response.data.reduce((total, obj) => obj.workedHours + total, 0))
                setTaskHours(hours)
            } else {
            }

        }).catch(error => {
            openSnackBar(i18n.t("worklogs.problemFetching"), "error")
        })
    }

    const getClientsRequest = () => {
        axios.get(getClients, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token")
            }
        }).then(response => {
            if (!response.data.length) {
                openSnackBar(i18n.t("filterModal.errorClients"), "error")
            } else {
                setClients(response.data)
            }
        })
    }

    const handleClientChange = (event, newValue) => {
        setSelectedClient(newValue)
    }

    const handleDateChange = date => {
        setDate(date.toDate())
    }

    const handleDateRangeChange = date => {
        setDateRange(date)
    }

    const handleUserChange = (event, newValue) => {
        setSelectedUser(newValue)
    }

    const transformDate = value => {
        let date = new Date(value),
            month = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        return [date.getFullYear(), month, day].join("-");
    }

    function RenderDatesTextfield({ openCalendar }) {
        return (
            <TextField
                label='Invoice date'
                variant="outlined"
                multiline
                maxRows={3}
                value={moment(date).format('DD-MM-YYYY')}
                onFocus={openCalendar}
                className={classes.generalField}
                style={{ width: 290 }}
                InputLabelProps={{
                    classes: {
                        root: classes.textFieldLabel,
                        focused: classes.focusedLabel
                    },
                }}
            />
        )
    }

    const loopTaskHours = () => {
        let data = []

        for (let [key, value] of Object.entries(taskHours).sort()) {
            data.push({ name: key, value: value })
        }

        setFormattedHours(data)
    }

    function base64toBlob(base64Data, contentType) {
        contentType = contentType || '';
        var sliceSize = 1024;
        var byteCharacters = atob(base64Data);
        var bytesLength = byteCharacters.length;
        var slicesCount = Math.ceil(bytesLength / sliceSize);
        var byteArrays = new Array(slicesCount);

        for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            var begin = sliceIndex * sliceSize;
            var end = Math.min(begin + sliceSize, bytesLength);

            var bytes = new Array(end - begin);
            for (var offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }

        return new Blob(byteArrays, { type: contentType });
    }


    async function mergeBetweenPDF(pdfFileList, number) {
        const doc = await PDFDocument.create()

        const getUserSignature = () => {
            switch (selectedUser.id) {
                case 1:
                    return CristianSerea

                case 2:
                    return MihailStoica

                default:
                    return null
            }

        }

        const pngURL = getUserSignature()
        const pngImageBytes = pngURL ? await fetch(pngURL).then((res) => res.arrayBuffer()) : null

        const pngImage = pngURL ? await doc.embedPng(pngImageBytes) : null
        const pngDims = pngURL ? pngImage.scale(0.4) : null

        const initialPDF = await PDFDocument.load(pdfFileList[0], { ignoreEncryption: true })
        const appendixPDF = await PDFDocument.load(pdfFileList[1])

        const initialPDFPages = await doc.copyPages(initialPDF, initialPDF.getPageIndices())

        for (const page of initialPDFPages) {
            if (pngURL) {
                page.drawImage(pngImage, {
                    x: page.getWidth() / 2 - pngDims.width / 2 + 200,
                    y: page.getHeight() / 2 - pngDims.height - 300,
                    width: pngDims.width,
                    height: pngDims.height,

                });
            }
            doc.addPage(page)
        }

        const appendixPDFPages = await doc.copyPages(appendixPDF, appendixPDF.getPageIndices())
        for (const page of appendixPDFPages) {
            if (pngURL) {
                page.drawImage(pngImage, {
                    x: page.getWidth() / 2 - pngDims.width / 2 + 220,
                    y: page.getHeight() / 2 - pngDims.height - 300,
                    width: pngDims.width,
                    height: pngDims.height,

                });
            }

            doc.addPage(page)
        }

        const base64 = await doc.saveAsBase64()

        const bufferArray = base64toBlob(base64, 'application/pdf')

        const blob = new Blob([bufferArray], { type: 'application/pdf' })

        saveAs(blob, JSON.parse(localStorage.getItem('user')).role === 1 ? `SM${number}.pdf` : `${JSON.parse(localStorage.getItem('user')).name.replace(/[a-z]/g, '').replace(/ /g, '')}${number}.pdf`)
    }

    const LazyDownloadPDFButton = (number, date, totalHours, formattedHours) => (
        <Button
            className={classes.download}
            style={{ backgroundColor: disabled ? Colors.secondColor : Colors.firstColor, color: Colors.whiteColor }}
            disabled={disabled}
            onClick={
                async () => {
                    const doc = <InvoicePDF number={number} date={date} totalHours={totalHours} formattedHours={formattedHours} />
                    const asPdf = pdf()

                    asPdf.updateContainer(doc)

                    let initialBlob = await new Blob([invoices], { type: 'application/pdf' }).arrayBuffer()
                    let appendixBlob = await (await asPdf.toBlob()).arrayBuffer()

                    mergeBetweenPDF([initialBlob, appendixBlob], number)
                }}>
            Download
        </Button>
    )

    const dragOver = (e) => {
        e.preventDefault()
    }

    const dragEnter = (e) => {
        e.preventDefault()
    }

    const dragLeave = (e) => {
        e.preventDefault()
    }

    const fileDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;

        setInvoices(files[0])
    }

    useEffect(() => {
        getClientsRequest()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (dateRange) {
            dateRange.map(data =>
                setDates(data)
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange])

    useEffect(() => {
        if (selectedUser === null) {
            setSelectedUser(admins[0])
        }
    }, [selectedUser])

    useEffect(() => {
        loopTaskHours()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [taskHours])

    useEffect(() => {
        if (JSON.parse(localStorage.getItem('user')).isEmployee === 0 && number && date && startDate && endDate) {
            getTaskHoursRequest()
            setDisabled(false)
        } else if (number && date && selectedClient && startDate && endDate && selectedUser) {
            getTaskHoursRequest()
            setDisabled(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [number, date, selectedClient, startDate, endDate, selectedUser])

    return (
        <div className={classes.root}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", backgroundColor: Colors.thirdColor, borderTopLeftRadius: 5, borderTopRightRadius: 5, maxHeight: 520 }}>
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: 20, paddingLeft: 20, paddingRight: 20, paddingBottom: 10 }}>
                    <div style={{ width: 290, height: 100, borderWidth: 1, borderStyle: 'dashed', cursor: 'pointer', marginTop: 30, marginBottom: 20, position: 'relative' }}
                        onDragOver={dragOver}
                        onDragEnter={dragEnter}
                        onDragLeave={dragLeave}
                        onDrop={fileDrop}
                        onClick={() => fileRef.current.click()}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', top: 20, bottom: 20, left: 20, right: 20, textAlign: 'center' }}>
                            <Typography>
                                {invoices ? invoices.name : 'Click to select a file or drag and drop a file'}
                            </Typography>
                        </div>

                        <input
                            ref={fileRef}
                            type='file'
                            style={{ display: 'none', height: 150, width: 200, backgroundColor: 'green' }}
                            onChange={event => setInvoices(event.target.files[0])}
                        />
                    </div>

                    <TextField
                        label='Invoice number'
                        variant="outlined"
                        onChange={event => setNumber(event.target.value)}
                        InputLabelProps={{
                            classes: {
                                root: classes.textFieldLabel,
                                focused: classes.focusedLabel
                            },
                        }}
                        style={{ width: 290 }}
                    />

                    <DatePicker
                        format="DD-MM-YYYY"
                        placeholder='Invoice date'
                        onChange={handleDateChange}
                        value={date}
                        type="custom"
                        weekStartDayIndex={1}
                        render={<RenderDatesTextfield />}
                        containerStyle={{ marginTop: 20, width: 290 }}
                        style={{ width: 290 }}
                    />

                    {JSON.parse(localStorage.getItem('user')).role === 1 && <Autocomplete
                        className={classes.generalField}
                        id="checkboxes-tags-demo"
                        disableClearable
                        options={clients}
                        onChange={handleClientChange}
                        getOptionSelected={(option) => option.name === selectedClient.name && option.name === selectedClient.name}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label='Client' />
                        )}
                        style={{ marginTop: 20, width: 290 }}
                    />}

                    <FormControl
                        className={classes.select}
                        variant="outlined"
                        style={{ marginTop: 20, width: 290 }}>
                        <InputLabel htmlFor="outlined-adornment-password">{i18n.t("filterModal.dateRange")}</InputLabel>
                        <OutlinedInput
                            id="outlined-adornment-password"
                            type="text"
                            value={moment(startDate).format('MMM DD, YYYY') + " - " + moment(endDate).format('MMM DD, YYYY')}
                            onClick={() => {
                                dateRangeModalRef.current.setOpen(true)
                                dateRangeModalRef.current.setDate([
                                    {
                                        startDate: new Date(),
                                        endDate: new Date(),
                                        key: 'selection'
                                    }
                                ])
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        disableRipple={true}
                                        className={classes.dateButton}
                                        aria-label="toggle password visibility"
                                        onClick={() => {
                                            dateRangeModalRef.current.setOpen(true)
                                            dateRangeModalRef.current.setDate([
                                                {
                                                    startDate: new Date(),
                                                    endDate: new Date(),
                                                    key: 'selection'
                                                }
                                            ])
                                        }}
                                    >
                                        <DateRangeIcon
                                            style={{ width: 22, height: 22 }} />
                                    </IconButton>
                                </InputAdornment>
                            }
                            label='Date range'
                            labelWidth={82}
                        />
                    </FormControl>

                    {JSON.parse(localStorage.getItem('user')).role === 1 && <Autocomplete
                        className={classes.generalField}
                        disableClearable
                        options={admins}
                        onChange={handleUserChange}
                        defaultValue={admins[0]}
                        getOptionSelected={(option) => option.id === selectedUser.id}
                        getOptionLabel={(option) => (option.title)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label='Admin signature' />
                        )}
                        style={{ marginTop: 20, width: 290, marginBottom: 10 }}
                    />}
                </div>

                {LazyDownloadPDFButton(number, moment(date).format('DD.MM.YYYY'), totalHours, formattedHours, selectedUser)}
            </div>

            {formattedHours && formattedHours.length ?
                <div style={{ display: 'flex', marginLeft: 20, maxHeight: height - 105, width: width, overflowY: 'auto', flexDirection: 'column', backgroundColor: Colors.thirdColor, borderRadius: 5, padding: 20 }}>
                    <Typography style={{ textAlign: 'center', fontSize: 18, fontWeight: 700 }}>
                        Anexă la factura NR. {number} din data de {moment(date).format('DD.MM.YYYY')}
                    </Typography>

                    <Typography style={{ marginTop: 20, fontSize: 14, fontWeight: 700 }}>
                        Prestări servicii programare orientată {totalHours} ore total.
                    </Typography>


                    {formattedHours.map((value, index) => {
                        return (
                            <div key={index} style={{ marginTop: 20 }}>
                                <Typography style={{ fontSize: 14, fontWeight: 700 }}>
                                    {value.name} - {value.value.reduce((total, obj) => obj.workedHours + total, 0)}h
                                </Typography>
                                {value.value.map((hour, index) => (
                                    <Typography key={index} style={{ marginLeft: 20, fontSize: 14 }}>
                                        ● {hour.task} - {hour.workedHours}h
                                    </Typography>
                                )
                                )}
                            </div>
                        )
                    }
                    )}
                </div> : null}

            <DateRangeModal ref={dateRangeModalRef} onChangeDate={handleDateRangeChange} />
            <SnackBar ref={snackbarRef} />
        </div >
    )
}